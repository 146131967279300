import { isPremium } from '../../common/utils/user'
import { CommunityDto, UserDto } from '../../shared/types/api'

let CommunityUtils = {
    canAddCommunity: (user: UserDto) => {
        return isPremium(user)
    },

    /**
     * Преобразуем сообщества в объект на фронте
     * @param data
     * @returns {*|*[]}
     */
    parseCommunities: (data: Array<CommunityDto>) => data?.map((community) => CommunityUtils.parseCommunity(community)) || [],

    /**
     * Преобразуем сообщество в объект на фронте
     * @returns {*|*[]}
     * @param community
     */
    parseCommunity: (community: CommunityDto): CommunityDto => {
        return {
            id: community.id,
            name: community.name,
            canEdit: community.canEdit,
            adminLogin: community.adminLogin,
            description: community.description,
            inviteKey: community.inviteKey,
            hasLike: community.hasLike,
            likes: community.likes,
            tags: community.tags,
            active: false,
            type: community.type
        }
    }
}

export default CommunityUtils
