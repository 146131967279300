import {
    executeDeleteMethod,
    executeJsonGetMethod,
    executeJsonPostMethod, executeJsonPutMethod
} from '../api/rest/restApiUtils'
import { CommunityType } from '../../const/CommunityType'
import { CommunityDto } from '../types/api'

/**
 * Создание сообщества
 * @method create
 * @param  {string}  communityName - название сообщества
 * @param  {string}  communityDescription - описание сообщества
 * @return {Response} Fetch Response
 */
export function create(communityName: string, communityDescription: string, type: CommunityType, tags: Array<string>) {
    let sendingData = {
        communityName: communityName,
        communityDescription: communityDescription,
        type: type,
        tags: tags
    }
    return executeJsonPostMethod('/user/community', sendingData).then((resp) => {
        return resp.data
    })
}

/**
 * Получение сообщества по id
 * @method getCommunity
 * @param  {number}  uid - id сообщества
 * @return {Response} Fetch Response
 */
export function getCommunity(uid: string) {
    return executeJsonGetMethod('/find/community/' + uid).then((resp) => {
        return resp.data
    })
}

/**
 *Получение списка открытых сообществ, которых нет у пользователя
 * @method getCommunity
 * @return {Response} Fetch Response
 */
export function findAllPublicCommunityUserNotHas() {
    return executeJsonGetMethod('/communities/public').then((resp) => {
        return resp.data.content
    })
}

/**
 * Получение полной иформации про сообщество
 * @method getFullCommunityInfo
 * @param  {number}  uid - id сообщества
 * @return {Response} Fetch Response
 */
export function getFullCommunityInfo(uid: string) {
    return executeJsonGetMethod('/find/community/full/' + uid).then((resp) => {
        return resp.data
    })
}

/**
 * Подключение к сообществу
 * @method joinToCommunity
 * @param  {object}  community - сообщество
 * @return {Response} Fetch Response
 */
export function joinToCommunity(community: { inviteKey: string }) {
    return executeJsonPutMethod('/user/community/' + community.inviteKey)
}

/**
 * Получение сообществ
 * @method getCommunities
 * @return {Response} Fetch Response
 */
export function getCommunities() {
    return executeJsonGetMethod('/user/communities').then((resp) => {
        return resp.data.content
    })
}

/**
 * Получение сообществ
 * @method getCommunities
 * @return {Response} Fetch Response
 */
export function updateCommunity(id: number, dto: { name: string, description: string, tags: Array<string> }) {
    return executeJsonPutMethod(`/user/communities/${id}`, dto).then((resp) => {
        return resp.data as CommunityDto
    })
}

/**
 * Выход из сообщества
 * @method leaveCommunity
 * @param  {number}  id - id сообщества
 * @return {Response} Fetch Response
 */
export function leaveCommunity(id: number) {
    return executeDeleteMethod('/user/community/' + id)
}

/**
 * Выход из сообщества
 * @method leaveCommunity
 * @param  {number}  id - id сообщества
 * @return {Response} Fetch Response
 */
export function likedOrDislikedCommunity(id: number) {
    return executeJsonPutMethod(`/user/communities/${id}/likes`)
}

/**
 * Копировать личный список слов в сообщество
 * @method leaveCommunity
 * @param  {number}  id - id сообщества
 * @return {Response} Fetch Response
 */
export function cloneListToCommunity(communityId: number, listId: number) {
    return executeJsonPostMethod(`/user/community/${communityId}/wordlist/${listId}`)
}

