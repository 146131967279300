import { Button, Modal } from 'antd'
import { FC, useEffect, useState } from 'react'
import Loading from '../elements/loading/Loading'
import { getPayStatus } from '../../shared/http/purchases'

interface LoginCodeDialogProps {

    closeDialog: () => void,
    open: boolean;
    paymentId?: number
}

const CoursePayLoadingDialog: FC<LoginCodeDialogProps> = ({ closeDialog, open, paymentId }) => {

    const POLLING_INTERVAL = 5000 // Poll every 5 seconds

    const [status, setStatus] = useState<string | undefined>(undefined)
    const [intervalId, setIntervalId] = useState<any>(undefined)

    useEffect(() => {

        if (open && !status) {
            const intervalId = setInterval(() => {
                fetchData()
            }, POLLING_INTERVAL)
            setIntervalId(intervalId)
        }
        if (!open && intervalId) {
            clearInterval(intervalId)
        }
        console.log(status)

    }, [status, open])


    const fetchData = () => {
        getPayStatus(paymentId).then(resp => {
            setStatus(resp.data)
        })
    }

    return (
        <Modal title='Ожидание оплаты'
               onCancel={closeDialog}
               footer={[
                   <Button key='submit' onClick={closeDialog}>
                       Отмена
                   </Button>,
                   <Button key='submit' onClick={closeDialog}>
                       Ok
                   </Button>

               ]}
               open={open}>
            <Loading />
        </Modal>
    )
}

export default CoursePayLoadingDialog