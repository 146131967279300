import {
    executeJsonPostMethod
} from '../api/rest/restApiUtils'
import { WordDto } from '../types/api'

/**
 * generateAIWordListCard
 * @method create
 * @param  {number}  cardList - id списка слов
 * @return {Response} Fetch Response
 */
export function generateAIWordListCard(cardList: number, words: Array<WordDto>) {
    let canceledWords = {
        words: words.map(w => {
            return {
                ...w,
                id: 0
            }
        })
    }
    return executeJsonPostMethod(`/openai/wordlist/${cardList}/ai-card`, canceledWords).then((resp) => {
        return resp.data
    })
}



