import '../../../../styles/scss/course/course-item.scss'
import { Flex } from 'antd'
import { PlayIcon } from '../../../elements/svg_icons/PlayIcon'
import { FC } from 'react'
import { ModulesDto } from '../../../../shared/types/api'

interface CourseItemProps {
    selectModule: (module: ModulesDto) => void,
    module: ModulesDto
}

export const CourseItem: FC<CourseItemProps> = ({ module, selectModule }) => {

    return (
        <Flex className='course-item__container' gap='8px'>
            <PlayIcon />
            <Flex vertical justify='space-around' onClick={() => selectModule(module)}>
                <span className='course-item__text'>{module.title}</span>
                <span className='course-item__text-time'>10:44</span>
            </Flex>
        </Flex>
    )
}