import {
    executeJsonGetMethod
} from '../api/rest/restApiUtils'

/**
 * Получение курсов
 * @method getCourses
 * @return {Response} Fetch Response
 */
export function getCourses() {
    return executeJsonGetMethod('/courses').then((resp) => {
        return resp.data
    })
}


/**
 * Получение курса по ID c видео материалами
 * @method getCourses
 * @return {Response} Fetch Response
 */
export function getCourse(id: number) {
    return executeJsonGetMethod('/courses/' + id).then((resp) => {
        return resp.data
    })
}


/**
 * Получение курса по ID c видео материалами
 * @method getCourses
 * @return {Response} Fetch Response
 */
export function getCourseOverview(id: number) {
    return executeJsonGetMethod('/courses/' + id + '/overview').then((resp) => {
        return resp.data
    })
}


