import '../../styles/css/dictionary/modalwindow/main_dialog.css'
import * as API_WORDLIST from '../../shared/http/word-list'
import * as API_SUBMIT_WORDLIST from '../../shared/http/submitWordList'
import { Button, Modal, Typography } from 'antd'
import SimpleSelector from '../elements/selectors/SimpleSelector'
import { GEButton } from '../../common/buttons'
import { FC, useEffect, useState } from 'react'
import WordListType from '../../const/WordListType'
import { useAppSelector } from '../../store/hooks'
import { userSelector } from '../../store/global'

const { Paragraph } = Typography

interface SubmitDoneListModalDialogProps {
    closeDialog: () => {},
    showModal: boolean
}

const SubmitDoneListModalDialog: FC<SubmitDoneListModalDialogProps> = ({ closeDialog, showModal }) => {

    const user = useAppSelector(userSelector)
    const [wordLists, setWordLists] = useState([])
    const [showWordListLoader, setShowWordListLoader] = useState(false)
    const [selectedWordListId, setSelectedWordListId] = useState(undefined)


    useEffect(() => {
        if (user) {
            loadUserWordLists()
        }
        return () => {
        }
    }, [user])


    /**
     * Загрузить слова пользователя
     */
    const loadUserWordLists = () => {
        setShowWordListLoader(true)
        API_WORDLIST.getWordLists()
            .then((data) => {
                if (Array.isArray(data)) {
                    // @ts-ignore
                    setWordLists(data.filter(wl => wl.type !== WordListType.DONE))
                }
            })
            .catch((err) => {
                Modal.error({
                    title: 'Ошибка!',
                    content: 'Не удалось загрузить слова 123'
                })
            })
            .finally(() => {
                setShowWordListLoader(false)
            })
    }

    /**
     * Изменить wordList
     * @param event.target.value - id выбранного списка
     */
    const changeWordList = (event: any) => {
        let selectedWordListId = event.target.value
        setSelectedWordListId(selectedWordListId)
    }

    const submitList = () => {
        API_SUBMIT_WORDLIST.submit(selectedWordListId)
            // @ts-ignore
            .then(() => {

                Modal.success({
                    centered: true,
                    destroyOnClose: true,
                    okText: 'Понятно',
                    // @ts-ignore
                    getContainer: () => document.querySelector('.done_word_main'),
                    content: (
                        <Paragraph style={{ margin: '0 0 0 30px' }}>
                            Спасибо за список, постараемся рассмотреть его в ближайшее время!
                        </Paragraph>
                    )
                })
            })
            .finally(() => {
                closeDialog()
            })
    }


    return (
        <>
            <Modal title='Предложить свой список слов' open={showModal}
                   onOk={() => {
                   }} onCancel={closeDialog}
                   footer={[
                       <Button key='back' onClick={closeDialog}>
                           Отмена
                       </Button>
                   ]}
            >
                <p>Предлагая свой список, вы можете выиграть премиум на сайте! Помогите нам собрать базу полезных
                    карточек</p>
                <SimpleSelector
                    title={'Списки карточек:'}
                    showLoader={showWordListLoader}
                    onChange={changeWordList}
                    dataList={wordLists}
                    dataValue={'listName'}
                    defaultValue={'Выберите список карточек'}
                    selectableDefaultValue={false}
                    disabled={false}
                />
                {selectedWordListId && (
                    <GEButton onClick={submitList} type={'primary'}>
                        Поделиться списком
                    </GEButton>
                )}
            </Modal>
        </>
    )
}

export default SubmitDoneListModalDialog
