import { FC, useState } from 'react'
import '../../styles/css/dictionary/modalwindow/modalw.css'
import { Button, Input, Modal } from 'antd'
import * as API_WORD from '../../shared/http/word'
import WordUtils from '../utils/WordUtils'
import Constants from '../../const/Constants'
import { ID } from '../../shared/types/api'

interface AddWordsDictionaryDialogProps {
    activeWordList: { id: ID },
    cancelWindow: () => void,
    loadWords: (id: ID) => void,
    open: boolean
}

const AddWordsDictionaryDialog: FC<AddWordsDictionaryDialogProps> = ({
                                                                         activeWordList,
                                                                         cancelWindow,
                                                                         loadWords,
                                                                         open
                                                                     }) => {
    const [textValue, setTextValue] = useState<string>('')
    const [valid, setValid] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const validate = (array: any) => {
        const myRegexp = new RegExp(WordUtils.getWordsPattern(), 'g')
        const validArray =
            //@ts-ignore
            !array.some((e) => !e.match(myRegexp)) && array.length <= Constants.MAX_COUNT_WORDS_IN_WORD_LIST
        setValid(validArray)
        return validArray
    }

    const changeValue = (e: any) => {
        const text = e.target.value
        let array = []
        if (text.includes('\n')) {
            array = text.trim().split('\n')
        } else {
            array.push(text)
        }
        validate(array)
        setTextValue(text)
    }

    const saveWords = () => {
        setLoading(true)
        const array = textValue.trim().split('\n')
        if (validate(array)) {
            const wordsToSave = array.map((e) => {
                const regExpExecArray = new RegExp(WordUtils.getWordsPattern(), 'g').exec(e)
                return {
                    engWord: regExpExecArray ? regExpExecArray[2] : ' ',
                    rusWord: regExpExecArray ? regExpExecArray[3] : ' '
                }
            })

            if (activeWordList) {
                //@ts-ignore
                API_WORD.saveWords(activeWordList.id, wordsToSave, []).then(() => {
                    loadWords(activeWordList.id)
                }).finally(() => {
                    clearAndClose()
                })
            }
        }
    }

    const clearAndClose = () => {
        setLoading(false)
        setTextValue('')
        setValid(false)
        cancelWindow()
    }

    return (
        <Modal title='Добавление слов списком'
               onCancel={clearAndClose}
               footer={[
                   <Button key='submit' onClick={clearAndClose}>
                       Отмена
                   </Button>,
                   <Button
                       disabled={!valid}
                       onClick={saveWords}
                       loading={loading}
                       type='primary'
                   >
                       Сохранить
                   </Button>
               ]}
               open={open}>
            <span>Введите слова в формате "English - Английский"</span>
            <Input.TextArea
                status={valid ? '' : 'error'}
                className='ta-auto__words'
                autoSize={{ minRows: 5 }}
                placeholder={'Example - Пример \n' + 'Cat - Кошка \n' + 'Dog - собака '}
                value={textValue}
                onChange={changeValue}
            />
        </Modal>
    )
}

export default AddWordsDictionaryDialog
