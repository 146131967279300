import { FC } from 'react'
import ReactPlayer from 'react-player'

interface VideoSectionProps {
    title: string,
    gifLink: string
}

//Todo link
export const VideoSection: FC<VideoSectionProps> = ({ title, gifLink }) => {
    return (
        <section className='premium__video-area'>
            <div className='premium__video-content'>
                <ReactPlayer controls={false}
                             loop={true}
                             playing={true}
                             muted={true}
                             url={gifLink}
                />
            </div>
            <div className='premium__video-title'>
                <span>{title}</span>
            </div>
        </section>
    )
}