import Constants from '../../../const/Constants'
import { GeekExceptionType } from '../../../common/exceptions/exception-types'
import { ApiError } from '../../../common/error/api-error'
import { getToken } from '../../../store/memory/memory'

const getJsonHeaders = () => {
    const jsonHeaders: HeadersInit = new Headers()
    jsonHeaders.set('Content-Type', 'application/json')
    jsonHeaders.set('Authorization', getToken())
    return jsonHeaders
}

const getHeaders = () => {
    const headers: HeadersInit = new Headers()
    headers.set('Authorization', getToken())
    return headers
}

export function executePostMethod(url: string, data?: any) {
    return fetch(`${Constants.queryConst + url}`, {
        method: 'POST',
        headers: getJsonHeaders(),
        body: data ? JSON.stringify(data) : null
    }).then((response) => {
        if (!response.ok) {
            throw response
        }
        return response
    })
}

export function executeJsonPostMethod(url: string, data?: any) {
    return fetch(`${Constants.queryConst + url}`, {
        method: 'POST',
        headers: getJsonHeaders(),
        body: data ? JSON.stringify(data) : null
    }).then((response) => {
        const json = response.json()
        if (!response.ok) {
            return json.then(e => {
                const type = e?.type
                throw new ApiError(type, type)
            })
        }
        return json
    })
}

export function executeFilePostMethod(url: string, data?: any) {
    return fetch(`${Constants.queryConst + url}`, {
        method: 'POST',
        headers: getHeaders(),
        body: data
    }).then((response) => {
        if (!response.ok) {
            throw response
        }
        return response
    })
}

export function executeGetMethod(url: string) {
    return fetch(`${Constants.queryConst + url}`, {
        headers: getHeaders()
    }).then((response) => {
        if (!response.ok) {
            throw response
        }
        return response
    })
}

export function executeJsonGetMethod(url: string) {
    return fetch(`${Constants.queryConst + url}`, {
        headers: getJsonHeaders()
    }).then((response) => {
        const json = response.json()
        if (!response.ok) {
            return json.then(e => {
                const msg = e?.error?.text ? e?.error?.text : 'Неизвестная ошибка'
                const code = e?.error?.code ? e?.error?.code : GeekExceptionType.GENERAL
                throw new ApiError(code, msg)
            })
        }
        return json
    })
}

export function executeDeleteMethod(url: string, data?: any) {
    return fetch(`${Constants.queryConst + url}`, {
        method: 'DELETE',
        headers: getJsonHeaders(),
        body: data ? JSON.stringify(data) : null
    }).then((response) => {
        if (!response.ok) {
            throw response
        }
        return response
    })
}

export function executePutMethod(url: string, data?: any) {
    return fetch(`${Constants.queryConst + url}`, {
        method: 'PUT',
        headers: getJsonHeaders(),
        body: data ? JSON.stringify(data) : null
    }).then((response) => {
        if (!response.ok) {
            throw response
        }
        return response
    })
}

export function executeJsonPutMethod(url: string, data?: any) {
    return fetch(`${Constants.queryConst + url}`, {
        method: 'PUT',
        headers: getJsonHeaders(),
        body: data ? JSON.stringify(data) : null
    }).then((response) => {
        if (!response.ok) {
            throw response
        }
        return response.json()
    })
}
