import {Button, Modal} from 'antd'
import {FC, useState} from 'react'
import TextArea from "antd/es/input/TextArea";
import * as API_TELEGRAM from "../../shared/http/telegram-notification";

interface ReviewModalProps {
    isOpen: boolean,
    close: () => void
}

const ReviewModal: FC<ReviewModalProps> = ({isOpen, close}) => {

    const [review, setReview] = useState<string>('')

    const clearAndClose = () => {
        setReview('')
        close()
    }

    const handleChangeReview = (event: any) => {
        setReview(event.target.value)
    }

    const sendReview = () => {
        API_TELEGRAM.notifyTelegramBot(review)
        const t = {
            title: 'Спасибо! Мы получили Ваш отзыв!',

        }
        Modal.success(t)
        clearAndClose()
    }


    return (
        <Modal title='Оставить отзыв'
               onCancel={clearAndClose}
               footer={[
                   <Button key='submit' onClick={clearAndClose}>
                       Отмена
                   </Button>,
                   <Button
                       onClick={sendReview}
                       type='primary'
                       disabled={!review}
                   >
                       Отправить
                   </Button>
               ]}
               open={isOpen}>
            <div className='modal-input-container'>

                <TextArea maxLength={600} rows={4} placeholder='Введите текст'
                          value={review}
                          onChange={(e) => handleChangeReview(e)}/>
            </div>
        </Modal>
    )
}
export default ReviewModal
