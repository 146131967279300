import { GEButton } from '../../../../../common/buttons'
import { FC } from 'react'

interface AddWordsButtonProps {
    selectedCategory: any,
    wordsAmount: number,
    showAddWordsModal: () => void
}

const AddWordsButton: FC<AddWordsButtonProps> = ({ selectedCategory, wordsAmount, showAddWordsModal }) => {
    return (
        <div
            className={selectedCategory !== null && wordsAmount === 0 ? '' : 'ge-invisible'}
            onClick={showAddWordsModal}
        >
            <GEButton>
                Добавить карточки по шаблону
            </GEButton>
        </div>
    )
}

export default AddWordsButton