import { Modal } from 'antd'
import { ApiError } from '../error/api-error'
import { GeekExceptionType } from '../exceptions/exception-types'
import { openPremiumPage } from './promocode'
import { NavigateFunction } from 'react-router/dist/lib/hooks'

export const handleHttpError = (error: ApiError, navigate: NavigateFunction): void => {
    if (error?.type === GeekExceptionType.CARD_LIMIT) {
        openPremiumPage(navigate)
    } else {
        Modal.error({
            title: 'Ошибка!',
            content: error.message
        })
    }
}