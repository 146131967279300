export enum DropdownProfileMenu {
    INVITE_FRIEND = 'INVITE_FRIEND',
    INTER_PROMOCODE = 'INTER_PROMOCODE',
    GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
    GET_AUTHORIZATION_CODE = 'GET_AUTHORIZATION_CODE',
    GO_TO_BOT = ' GO_TO_BOT',

    BIND_BOT = 'BIND_BOT',
    EDIT_PROFILE = 'EDIT_PROFILE',
    DELETE_PROFILE = 'DELETE_PROFILE',
    EXIT = 'EXIT',

}