import '../../../../../styles/scss/buttons/add-word-list-btn.scss'
import { GEButton } from '../../../../../common/buttons'
import { openPremiumPage } from '../../../../../common/utils/promocode'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddCardListDialog from '../../../../modalwindows/AddCardListDialog'

interface AddWordListButtonProps {
    canAddUser: boolean
}

/**
 * AddCommunityWordListButton.tsx аналог
 * @param canAddUser
 * @constructor
 */
const AddWordListButton: FC<AddWordListButtonProps> = ({
                                                           canAddUser
                                                       }) => {
    const navigate = useNavigate()
    const [showAddCardListModal, setShowAddCardListModal] = useState<boolean>(false)

    return (
        <div className='add-word-list-btn'>
            {canAddUser &&
            <GEButton type='primary' size='large' onClick={() => setShowAddCardListModal(true)}>
                Добавить список
            </GEButton>
            }
            {!canAddUser &&
            <li className='dictionary_left_bar-li-maxAmount' title='У вас максимум доступных списков'>
                Достигнут лимит:
                <GEButton onClick={() => openPremiumPage(navigate)} className={'margin10top'}>Купить премиум</GEButton>
            </li>}
            <AddCardListDialog isOpen={showAddCardListModal}
                               close={() => setShowAddCardListModal(false)} />
        </div>
    )
}

export default AddWordListButton
