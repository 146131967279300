import '../../../../styles/css/aferta/aferta.css'
import { Helmet } from 'react-helmet'

const AfertaPage = () => {
    return (
        <div id='aferta'>
            <Helmet title='Договор-оферта на оказание услуг' />
            <h2>Договор-оферта на оказание услуг</h2>
            <ul>
                1. Общие положения
                <li>
                    1.1 Данный документ является официальным предложением на заключение договора возмездного оказания
                    услуг (публичной офертой) Индивидуального предпринимателя Филатова Александра Вадимовича, именуемого
                    в дальнейшем «Исполнитель», и содержит все существенные условия предоставления Услуг любому
                    физическому лицу, именуемому в дальнейшем «Заказчик». Настоящая оферта не распространяется на
                    юридических лиц.
                </li>
                <li>
                    1.2. В соответствии с пунктом 3 статьи 438 Гражданского Кодекса Российской Федерации (далее ГК РФ) в
                    случае совершения лицом, получившим оферту действий по выполнению указанных в ней условий договора
                    (в частности, оплата услуг), действия считаются акцептом оферты. При этом договор считается
                    заключенным без подписания в каждом конкретном случае, так как акцепт оферты приравнивается к
                    заключению договора на указанных ниже условиях.
                </li>
                <li>
                    1.3 Акцептом настоящего Договора-оферты является полная оплата Заказчиком услуги по доступу к
                    платному функционалу, доступному на Сайте Исполнителя по адресам в сети Интернет:
                    https://geekenglish.ru
                </li>
                <li>
                    1.4. Совершая действия по акцепту настоящего Договора-оферты, Заказчик подтверждает свою
                    правоспособность, полномочия, дееспособность, достижение возраста 18 лет, а также законное право, не
                    оговоренное выше, вступать в договорные отношения с Исполнителем. Осуществляя Акцепт настоящей
                    Оферты, Заказчик гарантирует, что полностью ознакомлен с условиями, порядком оказания и оплаты
                    Услуг, а также признает безусловную пригодность платежной системы, предложенной Исполнителем для
                    оплаты Услуг, а также что оказание Исполнителем Услуг осуществляется дистанционно с использованием
                    сайта https://geekenglish.ru.
                </li>
                <li>
                    1.4.1. Совершая действия по акцепту настоящей Оферты, Заказчик также дает согласие на получение
                    рекламной рассылки от Исполнителя на адрес электронной почты, указанной Заказчиком при регистрации
                    на Сайте Исполнителя. Дает согласие на совершение телефонных звонков в адрес Заказчика Исполнителем
                    и (или) его представителем. Запись разговора Заказчика и Исполнителя (а равно его представителей)
                    может быть записана в целях контроля и улучшения качества работы. Заказчик дает согласие на запись
                    телефонного разговора. Срок действия согласия - с момента акцепта настоящей Оферты до момента отзыва
                    согласия, направляемого на электронный адрес Исполнителя, указанный в Договоре.
                </li>
                <li>
                    1.5. Иные термины и определения: Сайт ─ совокупность информации, текстов, графических элементов,
                    дизайна, изображений, фото- и видеоматериалов и иных результатов интеллектуальной деятельности, а
                    также программ для ЭВМ, содержащихся в информационной системе, обеспечивающей доступность такой
                    информации в сети Интернет по сетевому адресу: https://geekenglish.ru Услуги ─ оказание на
                    возмездной основе услуг по предоставлению доступа к дополнительному функционалу на сайте, а также
                    иные Услуги, указанные в п. 3.1. настоящего Договора. Услуги могут быть в следующих формах: Доступ к
                    дополнительному функционалу сайта – предоставление информационного доступа к закрытому функционалу
                    сайта Заказчик ─ любое дееспособное физическое или юридическое лицо, индивидуальный предприниматель,
                    акцептовавшие настоящую Оферту. Правообладатель ─ лицо, которому принадлежат интеллектуальные права
                    на Услуги, а также на Сайт и его содержание. Персональные данные ─ информация, относящаяся к
                    Заказчику, в том числе указанная им при оформлении Заказа. Обработка персональных данных ─ действия
                    (операции), производимые в ручном или автоматическом режиме с персональными данными Заказчика,
                    включая сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                    использование, распространение (в том числе передача), обезличивание, блокирование и уничтожение
                    персональных данных. Платформа - программно-аппаратные средства, права на которые принадлежат
                    третьим лицам, используемые Исполнителем для проведения обучения в интерактивном режиме и хранения
                    Обучающих Материалов Исполнителя. Исполнитель оставляет за собой право выбора любой Платформы для
                    размещения Обучающих Материалов (Getcourse, Justclick, иные платформы), в том числе размещать
                    Материалы на Платформах социальных сетей (ВКонтакте и др.).
                </li>
            </ul>
            <ul>
                2.Порядок заключения договора и условия
                <li>
                    2.1. Исполнитель обязуется по заданию Заказчика оказать последнему платные услуги по предоставлению
                    доступа к дополнительному функционалу сайта и (или) материалам в сети Интернет, а Заказчик обязуется
                    оплатить эти услуги в соответствии с Прейскурантом и Тарифами, утвержденным Исполнителем на дату
                    акцепта настоящей оферты.
                </li>
                <li>
                    2.2. Полный перечень функционала, их стоимость, содержания, размещен на официальном сайте
                    Исполнителя в сети Интернет на Сайте.
                </li>
                <li>
                    2.3. Заказчик самостоятельно знакомится на Сайте с описанием Услуг, их стоимостью, условиями
                    приобретения и способом получения.
                </li>
                <li>
                    2.4. С момента поступления денежных средств на счет (расчетный или банковский) Исполнителя в счет
                    оплаты Услуг настоящий Договор считается заключенным между Заказчиком и Исполнителем. При этом
                    заключение Договора в простой письменной форме не является обязательным.
                </li>
                <li>
                    2.5. Заказчик соглашается, что внесение изменений и дополнений в настоящий Договор влечет за собой
                    внесение изменений и дополнений в уже заключенный и действующий между Исполнителем и Заказчиком
                    Договор, и они вступают в силу в день публикации изменений на Сайте. При этом в части стоимости
                    Услуг за уже предоставленный доступ старая версия Договора продолжает действовать для Сторон без
                    изменений. В остальной части новые положения Договора имеют обратную силу.
                </li>
                <li>
                    2.6. Одновременно с настоящим Договором Заказчик принимает условия пользовательского соглашения,
                    размещенного на Сайте, пользовательское соглашение является неотъемлемой частью настоящего Договора.
                    Пользовательское соглашение: https://geekenglish.ru/personal-policy
                </li>
                <li>
                    2.7. Договор является абонентским (статья 429.4. Гражданского кодекса Российской Федерации).
                    Заказчик как абонент по Договору обязан уплатить Исполнителю вознаграждение в соответствии с
                    положениями Договора независимо от того, было ли затребовано им соответствующее исполнение от
                    Исполнителя.
                </li>
            </ul>
            {/*//ToDo*/}
            <ul>
                3.Предмет договора
                <li>
                    3.1. Предметом настоящего договора является оказание Исполнителем платных Услуг, указанных в п. 2.1.
                    настоящего Договора , предоставления информационных продуктов, предоставление доступа к материалам.
                </li>
                <li>
                    3.2.1. Для создания личного кабинета на сайте и открытия доступа к функционалу сайта, Заказчик
                    обязуется заполнить форму с указанием информации и данных о себе, необходимых Исполнителю для
                    оказания услуг. Указанные данные Исполнитель обрабатывает в соответствии с политикой использования
                    данных, размещенной по адресу в сети Интернет: https://geekenglish.ru/personal-policy. Исполнитель
                    гарантирует Заказчику конфиденциальность данных и иной информации, полученной от Заказчика.
                </li>
                <li>
                    3.2.2. Заказчик самостоятельно обеспечивает себя оборудованием, необходимым для получения Услуг и
                    обучения на сайте. Исполнитель не осуществляет техническое обслуживание или ремонт оборудования
                    Заказчика.
                </li>
                <li>
                    3.3. Исполнитель имеет право не предоставлять доступ к платным услугам , а также приостанавливать
                    доступ, в случаях нарушения Заказчиком своих обязательств по настоящему Договору, а именно: неполной
                    (ненадлежащей, несвоевременной) оплаты, сообщения неполной (недостоверной) информации,
                    непредставления (несвоевременного представления) регистрационных или иных данных необходимых для
                    оказания услуг.
                </li>
                <li>
                    3.4. Стороны пришли к соглашению об отсутствии необходимости подписания акта приема-передачи
                    оказанных услуг. При этом в случае отсутствия письменных претензий к качеству и объему оказанных
                    услуг в течение трех календарных дней со дня фактического окончания оказания услуг, оказанные услуги
                    считаются принятыми Заказчиком по качеству и объему, что приравнивается Сторонами настоящего
                    Договора к подписанию Акта приема-передачи оказанных услуг.
                </li>
                <li>
                    <ul>
                        4.Стоимость и порядок оплаты оказываемых услуг
                        <li>
                            4.1. Стоимость предоставляемых услуг определяется Исполнителем в одностороннем порядке в
                            российских рублях и размещается на Сайте.
                        </li>
                        <li>
                            4.2. Исполнитель вправе в одностороннем порядке изменять цены на предоставляемые Услуги,
                            информация о которых размещается на Сайте.
                        </li>
                        <li>
                            4.3. Датой вступления в силу новых цен и условий оплаты считается дата их размещения на
                            сайте Исполнителя.
                        </li>
                        <li>
                            4.4. Услуги, оказываемые по настоящему Договору, оплачиваются путем оплаты ежемесячной
                            оплаты подписки.
                        </li>
                        <li>
                            4.5. Оплата услуг по настоящему Договору производится в безналичном порядке. При этом
                            моментом оплаты является момент поступления денежных средств на расчетный счет Исполнителя.
                        </li>
                        <li>
                            4.5.1. В случае нарушения Заказчиком порядка оплаты оказываемых услуг, предусмотренных п.
                            4.4. Договора, доступ к услугам прекращается, уплаченные Заказчиком денежные средства
                            возврату не подлежат и удерживаются Исполнителем в качестве штрафа за неисполнение принятого
                            на себя Заказчиком обязательства.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul>
                5.Оформление Подписки
                <li>
                    5.1. Для получения Услуг Заказчик должен выбрать один из Тарифов, указанных на сайте, и оформить
                    подписку по форме, размещенной на Сайте по адресу страницы выбранной Услуги.
                </li>
                <li>
                    5.2. Все поля формы Заказа обязательны для заполнения Заказчиком. Исполнитель не проверяет
                    достоверность и актуальность данных, указанных Заказчиком. Заказчик не имеет права указывать при
                    оформлении Заказа данные третьих лиц.
                </li>
                <li>5.3. Подписка считается совершенной после отправки формы и оплаты выбранной Заказчиком Услуги.</li>
                <li>
                    5.4. Покупая подписку, Заказчик подтверждает, что ознакомился с настоящим договором-офертой,
                    политикой конфиденциальности, Тарифом и иной информацией, размещенной на Сайте.
                </li>
                <li>
                    5.5. Исполнитель вправе аннулировать Подписку, по которому не указаны данные, указанные в п. 5.2.
                    настоящего Договора.
                </li>
            </ul>
            <ul>
                6.Правила возврата денежных средств
                <li>
                    6.1. Заказчик имеет право потребовать от Исполнителя возврата оплаченных денежных средств без
                    объяснения причин, при условии направления Исполнителю скан-копии подписанного Заказчиком заявления
                    по форме, установленной в Приложении № 2 к настоящему Договору, и копии паспорта Заказчика на адрес
                    электронной почты Исполнителя: filatov.alexandr719@gmail.com с обязательным направлением оригинала
                    такого заявления по адресу: 390044, г. Рязань, Интернациональная 22, кв.69, не позднее 3 (Трех)
                    календарных дней со дня направления скан-копии заявления по электронной почте.
                </li>
                <li>
                    6.2. Требование Заказчика о возврате денежных средств должно быть написано и подписано Заказчиком
                    лично с обязательным указанием: фамилии, имени, отчества лица, которое требует возврата, а также
                    банковских реквизитов для осуществления перевода денежных средств. Денежные средства возвращаются
                    тому лицу, которое оплачивало Услуги.
                </li>
                <li>
                    6.3. Денежные средства за вычетом расходов Исполнителя, понесенных при оказании услуг до момента
                    поступления требования Заказчика о расторжении настоящего Договора и возврате денежных средств,
                    возвращаются на счет Заказчика, с которого производилась оплата Услуг в течение 10 календарных дней
                    с момента получения соответствующего заявления с учетом следующих правил:
                </li>
                <li>
                    6.3.1. В случае оплаты в кредит\рассрочку\иным образом, Исполнитель возвращает только стоимость
                    Тарифа участия и не возмещает проценты\расходы\комиссии, понесённые Заказчиком при заключении
                    договора с банком-партнёром или третьими лицами — посредниками. При этом денежные средства
                    возвращаются на счет, с которого поступили заемные средства.
                </li>
                <li>
                    6.3.2. Денежные средства, уплаченные Заказчиком за оказание Исполнителем Услуг, возвращаются
                    Заказчику в соответствии с правилами, определенными п.п. 6.10. – 6.11. настоящего Договора.
                </li>
                <li>
                    6.3.3. В соответствии с ч.2 ст. 781, ч.1 ст. 782 ГК РФ возможность возврата оплаты за Услуги, не
                    оказанные по вине Заказчика, не предусмотрена.
                </li>
                <li>
                    6.4. Сумма возврата рассчитывается на дату получения Исполнителем Заявления без учета каких-либо
                    скидок и специальных предложений.
                </li>
                <li>
                    6.5. Исполнитель не позднее 10 (десяти) календарных дней с момента получения от Пользователя по
                    электронной почте Заявления и доказательств направления оригинала такого Заявления на почтовый адрес
                    Исполнителя, производит возврат Пользователю денежных средств в соответствующем размере на указанные
                    им в Заявлении реквизиты либо, если реквизиты не указаны, по реквизитам с которых поступили денежные
                    средства при оплате.
                </li>
                <li>
                    6.6. Доступ к ресурсу прекращается в течение 1 (одного) рабочего дня с даты получения Исполнителем
                    от Пользователя Заявления о возврате денежных средств, оформленного по форме Исполнителя.
                </li>
                <li>
                    6.7. По согласованию с Пользователем Исполнитель может вместо возврата Пользователю денежных средств
                    в соответствии с Правилами возврата денежных средств осуществить замену Курса на другой Курс на
                    условиях, предложенных Исполнителем.
                </li>
            </ul>
            <ul>
                7.Порядок урегулирования споров
                <li>
                    7.1. Претензии Заказчика по предоставляемым услугам, за исключением случаев, указанных в п. 6.1.
                    настоящего Договора, принимаются Исполнителем к рассмотрению по электронной почте
                    filatov.alexandr719@gmail.com в течение трех календарных дней с момента возникновения спорной
                    ситуации.
                </li>
                <li>
                    7.2. Сторона, получившая претензию, обязана дать на нее ответ в течение десяти рабочих дней со дня
                    получения.
                </li>
                <li>
                    7.3. Направленные Исполнителю претензии рассматриваются в рамках действующего законодательства
                    Российской Федерации.
                </li>
                <li>7.4. Досудебный порядок урегулирования спора является обязательным для Сторон.</li>
                <li>
                    7.5. В случае урегулирования спора в судебном порядке, он передается на рассмотрение в
                    соответствующий суд по месту нахождения Исполнителя.
                </li>
            </ul>
            <ul>
                8.Расторжение, изменение и приостановление настоящего Договора
                <li>
                    8.1. Стороны вправе расторгнуть Договор по взаимному согласию в любой момент до начала фактического
                    исполнения Договора.
                </li>
                <li>
                    8.2. Оказание Услуг Исполнителем может быть приостановлено в связи с производственной необходимостью
                    либо в связи с проведением профилактических работ, а также в случаях, установленных
                    законодательством РФ, о чем Заказчик (Ученик) уведомляется в порядке, утвержденном Исполнителем или
                    в личном кабинете Заказчика.
                </li>
                <li>
                    8.3. Исполнитель вправе в одностороннем порядке без предварительного уведомления Заказчика
                    расторгнуть настоящий Договор в случае нарушения Заказчиком любых положений настоящего Договора,
                    пользовательского соглашения, в том числе, отказа от оплаты Услуг Исполнителя, а также использования
                    Услуг, материалов Курса и иной информации, ставшей доступной Заказчику в результате предоставления
                    доступа к Курсам, не в целях обучения, а в целях нанесения материального и (или) репутационного
                    ущерба Исполнителю, а также в иных целях, не связанных с обучением. Исполнитель уведомляет Заказчика
                    о расторжении настоящего Договора по адресу электронной почты Заказчика в течение 3 (Трех) рабочих
                    дней со дня расторжения настоящего Договора.
                </li>
                <li>
                    8.4. Исполнитель имеет право в любой момент изменять наименования, содержание, объемы, сроки,
                    условия и порядок оказания услуг по настоящему Договору, формирующих наполнение выбранного
                    Заказчиком Тарифов, в том числе расписание и порядок доступа к материалам.
                </li>
            </ul>
            <ul>
                9. Заключительные положения
                <li>
                    10.1. Заказчик дает согласие на обработку, хранение Исполнителем персональных данных Заказчика,
                    содержащихся в Заявке (фамилия, имя, отчество, возраст, электронный адрес, почтовый адрес, номер
                    телефона).
                </li>
                <li>
                    10.2. По всем вопросам, не урегулированным настоящим Договором, стороны руководствуются действующим
                    законодательством Российской Федерации.
                </li>
            </ul>
            ИП Филатов А.В. ОГРНИП 321623400037022 ИНН 622901533825
        </div>
    )
}

export default AfertaPage
