import { FC, useEffect, useState } from 'react'
import '../../styles/css/dictionary/modalwindow/main_dialog.css'
import '../../styles/css/dictionary/modalwindow/donewords_dialog.css'
import * as API_WORD from '../../shared/http/word'
import { Button, Modal, Table } from 'antd'
import { ID } from '../../shared/types/api'

interface DoneWordsModalDialogProps {
    open: boolean;
    closeDialog: () => void,
    selectedWordListId: ID,

}

const DoneWordsModalDialog: FC<DoneWordsModalDialogProps> = ({ open, closeDialog, selectedWordListId }) => {

    const [words, setWords] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (selectedWordListId) {
            API_WORD.getWords(selectedWordListId).then((resp) => {
                setWords([])
                setWords(resp)
                setLoading(false)
            })
        }
    }, [selectedWordListId])


    const columns = [
        {
            title: 'Сторона 1',
            dataIndex: 'engWord',
            key: 'id'
        },
        {
            title: 'Сторона 2',
            dataIndex: 'rusWord',
            key: 'id'
        }
    ]

    return (
        <Modal onCancel={closeDialog}
               open={open}
               footer={[
                   <Button key='submit' danger onClick={closeDialog}>
                       Закрыть
                   </Button>
               ]}
        >
            <div id='doneword-dialog'>
                <div id='doneword-dialog_content'>
                    <Table
                        columns={columns}
                        dataSource={words}
                        pagination={false}
                        loading={loading}
                        locale={{ emptyText: 'Не удалось загрузить слова' }}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DoneWordsModalDialog
